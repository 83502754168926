export class SortAndFilterOptions {

    page: number;
    itemsPerPage: number;
    sort: string;
    search: string;

    static of(options: any): SortAndFilterOptions {

        let sortAndFilterOptions: SortAndFilterOptions = new SortAndFilterOptions();

        sortAndFilterOptions.search = options['search'] || "";
        sortAndFilterOptions.page = options['page'];
        sortAndFilterOptions.itemsPerPage = options['itemsPerPage'];

        if (options['sortBy'].length > 0) {
            let sortDirection: string = options['sortDesc'][0] ? 'desc' : 'asc';
            let sortBy: string = options['sortBy'][0];

            if(sortBy === 'status.label'){
                sortBy = 'statusSort';
            }

            sortAndFilterOptions.sort = `${sortBy},${sortDirection}`;
        }

        return sortAndFilterOptions

    }

}
