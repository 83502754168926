import {Role} from "@/models/enums/Role";
import OrderStatus from "@/models/enums/OrderStatus";
import UserSession from "@/models/UserSession";
import Order from "@/models/Order";

export class TableUtils {

    static hideColumnsForRoles(headers: any, session: UserSession) {

        return headers.filter((header: any) => {
            return Role.isIn(header.roles, <Role>session.role);
        });

    }

}

export const sortDate: Function = (a, b) => {
    return Date.parse(b) - Date.parse(a);
}

export const sortAlphabetically: Function = (a, b) => {
    if (a < b) {
        return -1;
    }
    if (a > b) {
        return 1;
    }
    return 0;
};

export class ActionItem {

    title: string;
    action: Function
    allowedRoles: Array<Role>;
    allowedStatuses: Array<OrderStatus>;

    constructor(title: string, action: Function, allowedRoles?: Array<Role>, allowedStatuses?: Array<OrderStatus>) {
        this.title = title;
        this.action = action;
        this.allowedRoles = allowedRoles;
        this.allowedStatuses = allowedStatuses;
    }

    conditionsMet(session: UserSession, order: Order): boolean {

        if (Role.isIn(this.allowedRoles, session ? <Role>session.role : null) && OrderStatus.isIn(this.allowedStatuses, <OrderStatus>order.status)) {
            return true;
        }

        return false;

    }

}

