import {Sort} from "@/models/Sort";
import {Pageable} from "@/models/Pageable";

export class Page<T> {

    content: Array<T>;
    empty: boolean;
    first: boolean;
    last: boolean;
    number: number;
    numberOfElements: number;
    totalElements: number;
    totalPages: number;
    size: number;
    pageable: Pageable = {} as Pageable;
    sort: Sort = {} as Sort;

}
